import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs';

@Injectable()
export class AdminAgencyHttpService {
  private dataApiServer: string;
  private killbillServer: string;
  private authServer: string;

  constructor(@Inject('appUrls') appUrls: any, private http: HttpClient) {
    this.dataApiServer = appUrls.API_HTTPS_SERVER;
    this.killbillServer = appUrls.KILLBILL_HTTPS_SERVER;

    this.authServer = appUrls.AUTH_URL;
  }

  agencyFilterSearch(filterForm) {
    const apiUrl = this.dataApiServer + '/api-server/admin/agencies/get-filtered-pagination';

    const pageIndex = filterForm.pageIndex;
    const pageSize = filterForm.pageSize;
    const searchMultipleProperties = filterForm.searchMultipleProperties;
    const searchOperator = filterForm.searchOperator;
    const searchOperatorTwo = filterForm.searchOperatorTwo;
    const sortBy = filterForm.sortBy;
    const sortDirection = filterForm.sortDirection;
    const syblPermissions = filterForm.syblPermissions;
    const accountStatus = filterForm.accountStatus;
    const fieldOne = filterForm.fieldOne;
    const agency_id = filterForm.agency_id;


    const options = {
      params: new HttpParams()
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('agency_id',agency_id)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection)
        .set('syblPermissions', syblPermissions)
        .set('accountStatus', accountStatus)
        .set('fieldOne', fieldOne)
    };
    //return this.http.post(apiUrl, filterForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a agency return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.log('Error on Company', err);
        return err;
      })
    );
  }

  agencySelectedPropSearch(searchForm) {
    const apiUrl =
      this.dataApiServer + '/api-server/admin/agencies/get-selected-field-pagination';

    const searchField = searchForm.searchField;
    const pageIndex = searchForm.pageIndex;
    const pageSize = searchForm.pageSize;
    const searchMultipleProperties = searchForm.searchMultipleProperties;
    const searchOperator = searchForm.searchOperator;
    const searchOperatorTwo = searchForm.searchOperatorTwo;
    const sortBy = searchForm.sortBy;
    const sortDirection = searchForm.sortDirection;
    const syblPermissions = searchForm.syblPermissions;
    const accountStatus = searchForm.accountStatus;
    const fieldOne = searchForm.fieldOne;
    const agency_id = searchForm.agency_id;


    const options = {
      params: new HttpParams()
        .set('pageIndex', pageIndex)
        .set('agency_id',agency_id)
        .set('pageSize', pageSize)
        .set('syblPermissions', syblPermissions)
        .set('accountStatus', accountStatus)
        .set('fieldOne', fieldOne)
        .set('searchField', searchField)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection),
    };
    //return this.http.post(apiUrl, searchForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a agency return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.log('Error on Agency', err);
        return err;
      })
    );
  }

  deleteAgency(agency_id) {
    const body = {
      agency_id: agency_id,
    };
    const url = this.dataApiServer + '/api-server/admin/agencies/delete-agency';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        console.log('delete agency response', response);
        return response;
      })
    );
  }

  getAgencyById(agency_id) {
    const apiUrl =
      this.dataApiServer + '/api-server/admin/agencies/get-agency';

    const options = {
      params: new HttpParams().set('agency_id', agency_id),
    };
    //return this.http.post(apiUrl, searchForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a agency return an error.
        if (response.error != null) {
          const error = {
            error: response.error,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.log('Error on Agency', err);
        return err;
      })
    );
  }

  createHubspotRecord(data: any) {
    const url = this.dataApiServer + '/api-server/hubspot/process-onboarding';
    return this.http.post(url, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.log('Error on createHubspotRecord', err);
        return err;
      })
    );
  }

  closeWonDeal(dealId: string) {
    const url = this.dataApiServer + '/api-server/hubspot/deals/close/';
    return this.http.post(url, {dealId: dealId}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.debug('Error on closeWonDeal', err);
        return err;
      })
    );
  }

  getContactByEmail(email: string) {
    const url = this.dataApiServer + '/api-server/hubspot/contacts/search';
    return this.http.post(url, { email: email }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.debug('Error on getContactByEmail', err);
        return err;
      })
    );
  }

  getDealByContactAndCompanyAssociations(contactId: string) {
    const url = this.dataApiServer + '/api-server/hubspot/deals/search/contact-and-company';
    return this.http.post(url, { contactId: contactId }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.debug('Error on getDealByContactAndCompanyAssociations', err);
        return err;
      })
    );
  }
}
